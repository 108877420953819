import { Slider, Typography } from "@mui/material";

import { useData } from "@/contexts/DataContext";

const PricingMultipliersGroup = ({
  title,
  multipliers = [],
  setMultipliers = () => {},
}) => {
  const data = useData();

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "1rem",
        marginBottom: "1rem",
      }}
    >
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      {multipliers.map((multiplier, index) => (
        <div key={index} style={{ marginBottom: "1rem" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" gutterBottom>
              {multiplier.label}
            </Typography>
            <div
              style={{
                display: "flex",
                width: "85px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                Current:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {multiplier.value}
              </Typography>
            </div>
          </div>
          <Slider
            value={multiplier.value}
            step={data.priceMultiplierStep}
            min={data.minPriceMultiplier}
            max={data.maxPriceMultiplier}
            onChange={(e, newValue) => {
              setMultipliers((prev) => {
                const newMultipliers = [...prev];
                newMultipliers[index].value = newValue;
                return newMultipliers;
              });
            }}
          />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="body2" color="textSecondary">
              Min: {data.minPriceMultiplier}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Max: {data.maxPriceMultiplier}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingMultipliersGroup;
