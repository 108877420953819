import { useEffect, useState } from "react";
import { Alert, Container, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BottomButton from "@/components/BottomButton";
import Header from "@/components/Header";
import PricePreviewDrawer from "@/components/PricePreviewDrawer";
import Title from "@/components/Title";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import handleError from "@/utils/handleError";

import PricingMultipliersGroup from "./components/PricingMultipliersGroup";

const PricingPage = () => {
  const navigate = useNavigate();
  const data = useData();
  const [configuration, setConfiguration] = useState(null);
  const [basePrice, setBasePrice] = useState("");
  const [cuisineMultipliers, setCuisineMultipliers] = useState([]);
  const [mealTypeMultipliers, setMealTypeMultipliers] = useState([]);
  const [mealTimeMultipliers, setMealTimeMultipliers] = useState([]);
  const [periodMultipliers, setperiodMultipliers] = useState([]);
  const [isPricePreviewOpen, setIsPricePreviewOpen] = useState(false);

  const preparePricingData = () => {
    const createMultipliers = (multipliers, category) => {
      return multipliers.map((item) => ({
        [category]: item.state,
        multiplier: item.value,
      }));
    };
    return {
      basePrice: +basePrice,
      cuisineMultipliers: createMultipliers(cuisineMultipliers, "cuisine"),
      mealTypeMultipliers: createMultipliers(mealTypeMultipliers, "mealType"),
      mealTimeMultipliers: createMultipliers(mealTimeMultipliers, "mealTime"),
      periodMultipliers: createMultipliers(periodMultipliers, "period"),
    };
  };

  const submitHandler = async () => {
    try {
      if (!basePrice) {
        return toast.error("Base price is required");
      }
      const pricingData = preparePricingData();
      await api.kitchenRegistrations.updatePricing(pricingData);
      navigate(`/kitchen-registration/bank-details`);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const res = await api.kitchenRegistrations.getMyKitchenRegistration();
        const { configuration, pricing } = res.data.kitchenRegistration;

        if (!configuration) {
          return navigate(`/kitchen-registration/configuration`, {
            replace: true,
          });
        }

        setConfiguration(configuration);

        let cuisineMultipliers = configuration.cuisines.map((cuisine) => ({
          state: cuisine,
          label: data.cuisineLabels[cuisine],
          value: data.cuisineMultipliers[cuisine],
        }));

        let mealTypeMultipliers = configuration.mealTypes.map((mealType) => ({
          state: mealType,
          label: data.mealTypeLabels[mealType],
          value: data.mealTypeMultipliers[mealType],
        }));

        let mealTimeMultipliers = configuration.mealTimes.map((mealTime) => ({
          state: mealTime,
          label: data.mealTimeLabels[mealTime],
          value: data.mealTimeMultipliers[mealTime],
        }));

        let periodMultipliers = configuration.periods.map((period) => ({
          state: period,
          label: data.periodLabels[period],
          value: data.periodMultipliers[period],
        }));

        if (pricing) {
          setBasePrice(pricing.basePrice);

          const createMultiplierMap = (category, list) => {
            const multiplierMap = {};
            list.forEach((item) => {
              multiplierMap[item[category]] = item.multiplier;
            });
            return multiplierMap;
          };

          const cuisineMultiplierMap = createMultiplierMap(
            "cuisine",
            pricing.cuisineMultipliers,
          );
          const mealTypeMultiplierMap = createMultiplierMap(
            "mealType",
            pricing.mealTypeMultipliers,
          );
          const mealTimeMultiplierMap = createMultiplierMap(
            "mealTime",
            pricing.mealTimeMultipliers,
          );
          const periodMultiplierMap = createMultiplierMap(
            "period",
            pricing.periodMultipliers,
          );

          cuisineMultipliers = cuisineMultipliers.map((item) => {
            if (cuisineMultiplierMap[item.state]) {
              return {
                ...item,
                value: cuisineMultiplierMap[item.state],
              };
            }
            return item;
          });

          mealTypeMultipliers = mealTypeMultipliers.map((item) => {
            if (mealTypeMultiplierMap[item.state]) {
              return {
                ...item,
                value: mealTypeMultiplierMap[item.state],
              };
            }
            return item;
          });

          mealTimeMultipliers = mealTimeMultipliers.map((item) => {
            if (mealTimeMultiplierMap[item.state]) {
              return {
                ...item,
                value: mealTimeMultiplierMap[item.state],
              };
            }
            return item;
          });

          periodMultipliers = periodMultipliers.map((item) => {
            if (periodMultiplierMap[item.state]) {
              return {
                ...item,
                value: periodMultiplierMap[item.state],
              };
            }
            return item;
          });
        }

        setCuisineMultipliers(cuisineMultipliers);
        setMealTypeMultipliers(mealTypeMultipliers);
        setMealTimeMultipliers(mealTimeMultipliers);
        setperiodMultipliers(periodMultipliers);
      } catch (error) {
        handleError(error);
      }
    };
    fetchPricing();
  }, [data, navigate]);

  const pricePreviewOpenHandler = () => {
    if (!basePrice) {
      return toast.error("Base price is required");
    }
    setIsPricePreviewOpen(true);
  };

  return (
    <>
      <Header />
      <Container>
        <Title>Pricing</Title>
        <Alert severity="info" style={{ marginBottom: 2 }}>
          Set a base price and adjust the multipliers. The final price of a
          category is calculated by adding up the base price with the
          contributions from respective multipliers. A multiplier above 1
          increases it (e.g., 1.2 gives +20% of the base price), while a
          multiplier below 1 decreases it (e.g., 0.8 gives -20% of the base
          price). You can preview the prices for different categories from
          below.
        </Alert>
        <TextField
          size="small"
          style={{ marginBottom: 2 }}
          fullWidth
          label="Base Price (₹)"
          value={basePrice}
          onChange={(e) => {
            const input = e.target.value;
            const regex = /^[0-9]*$/;
            if (regex.test(input)) {
              setBasePrice(input);
            }
          }}
        />
        <PricingMultipliersGroup
          title={"Cuisine Multipliers"}
          multipliers={cuisineMultipliers}
          setMultipliers={setCuisineMultipliers}
        />
        <PricingMultipliersGroup
          title={"Meal Type Multipliers"}
          multipliers={mealTypeMultipliers}
          setMultipliers={setMealTypeMultipliers}
        />
        <PricingMultipliersGroup
          title={"Meal Time Multipliers"}
          multipliers={mealTimeMultipliers}
          setMultipliers={setMealTimeMultipliers}
        />
        <PricingMultipliersGroup
          title={"Period Multipliers"}
          multipliers={periodMultipliers}
          setMultipliers={setperiodMultipliers}
        />
        <BottomButton onClick={pricePreviewOpenHandler} arrowDirection="upward">
          Price preview
        </BottomButton>
      </Container>
      {isPricePreviewOpen && (
        <PricePreviewDrawer
          isOpen={isPricePreviewOpen}
          closeDrawer={() => setIsPricePreviewOpen(false)}
          configuration={configuration}
          pricing={preparePricingData()}
          continueHandler={submitHandler}
        />
      )}
    </>
  );
};

export default PricingPage;
