export const getCurrentDay12AM = () => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.toISOString();
};

export const getNextDay12AM = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.toISOString();
};

export const isCurrentDate = (date) =>
  new Date(date).getTime() === new Date(getCurrentDay12AM()).getTime();

export const railwayTimeToSeconds = (timeStr) => {
  // Split the input time string into hours and minutes
  const [hours, minutes] = timeStr.split(":").map(Number);

  // Validate input
  if (
    isNaN(hours) ||
    isNaN(minutes) ||
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59
  ) {
    throw new Error("Invalid time format");
  }

  // Calculate total seconds from midnight
  return hours * 3600 + minutes * 60;
};

export const getCurrentTimeInSeconds = () => {
  const now = new Date();

  // Get the hours, minutes, and seconds
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  // Calculate total seconds since midnight
  return hours * 3600 + minutes * 60 + seconds;
};

export const convertToAMPM = (time) => {
  // First, let's handle the case where the input might use a dot instead of a colon
  time = time.replace(".", ":");

  // Split the time string into hours and minutes
  let [hours, minutes] = time.split(":");

  // Convert hours to a number
  hours = parseInt(hours, 10);

  // Determine if it's AM or PM
  let period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Pad single-digit hours with a leading zero
  hours = hours.toString().padStart(2, "0");

  // Ensure minutes are padded with a leading zero if necessary
  minutes = minutes.padStart(2, "0");

  // Return the formatted time string
  return `${hours}:${minutes} ${period}`;
};

export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return (
    date.toDateString() +
    " " +
    date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
};

export const getDayFromDate = (date) => {
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  return days[new Date(date).getDay()];
};

export const isDateValid = (dateStr) => {
  return !isNaN(new Date(dateStr).getTime());
};

export const resetDateTo12AM = (date) => {
  let result = new Date(date);
  result.setHours(0, 0, 0, 0);
  return result.toISOString();
};
