import auth from "./auth";
import data from "./data";
import deliveries from "./deliveries";
import dispatchTasks from "./dispatchTasks";
import kitchenRegistrations from "./kitchenRegistrations";
import kitchens from "./kitchens";
import kitchenUserInvites from "./kitchenUserInvites";
import kitchenUsers from "./kitchenUsers";
import menus from "./menus";
import menuSchedules from "./menuSchedules";
import orders from "./orders";
import pincode from "./pincode";
import ratings from "./ratings";
import settlements from "./settlements";
import users from "./users";

const api = {
  data,
  auth,
  users,
  kitchenRegistrations,
  pincode,
  kitchens,
  menuSchedules,
  menus,
  kitchenUserInvites,
  kitchenUsers,
  dispatchTasks,
  deliveries,
  orders,
  ratings,
  settlements,
};

export default api;
