import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AuthContextProvider } from "./contexts/AuthContext";
import { DataContextProvider } from "./contexts/DataContext";
import { KitchenContextProvider } from "./contexts/KitchenContext";
import { setupAxios } from "./setup/axios";
import { Loader } from "./setup/loading";
import { Router } from "./setup/routing";
import { ThemeProvider } from "./setup/theme";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  setupAxios();
  return (
    <AuthContextProvider>
      <KitchenContextProvider>
        <DataContextProvider>
          <ThemeProvider>
            <BrowserRouter>
              <ToastContainer />
              <Loader />
              <Router />
            </BrowserRouter>
          </ThemeProvider>
        </DataContextProvider>
      </KitchenContextProvider>
    </AuthContextProvider>
  );
};

export default App;
