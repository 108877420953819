import React, { useState } from "react";
import { ArrowUpward } from "@mui/icons-material";
import { Button, Card, CardContent } from "@mui/material";

import LabelValueText from "@/components/LabelValueText";
import MapDrawer from "@/components/MapDrawer";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CardHeader from "./common/CardHeader";

const AddressDisplay = ({ isEditable, address }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Card style={{ margin: verticalComponentMargin }}>
        <CardContent>
          <CardHeader
            title={"Address"}
            editPath={isEditable && "/kitchen-registration/address"}
          />
          <LabelValueText label="Building" value={address.building} />
          <LabelValueText label="Area" value={address.area} />
          {address.landmark && (
            <LabelValueText label="Landmark" value={address.landmark} />
          )}
          <LabelValueText label="District" value={address.district} />
          <LabelValueText label="State" value={address.state} />
          <LabelValueText label="Country" value={address.country} />
          <LabelValueText label="Pincode" value={address.pincode} />
          <Button
            fullWidth
            size="small"
            variant="contained"
            onClick={() => setIsOpen(true)}
            endIcon={<ArrowUpward />}
            style={{ marginTop: "10px" }}
          >
            View location on Map
          </Button>
        </CardContent>
      </Card>
      <MapDrawer
        isEditable={false}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        address={address}
        useLatLng={true}
        btnName="Close"
        btnClickHandler={() => setIsOpen(false)}
        btnArrowHidden
      />
    </>
  );
};

export default AddressDisplay;
