import { ArrowForward } from "@mui/icons-material";
import { Button, Card, CardContent, ListItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NewRegistration = () => {
  const navigate = useNavigate();
  return (
    <div style={{ margin: "20px 0px" }}>
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Welcome to Mealsign Kitchen!
          </Typography>
          <Typography variant="body2" paragraph>
            We're thrilled to have you join our culinary community. Let's embark
            on a delightful journey together!
          </Typography>
        </CardContent>
      </Card>
      <Button
        onClick={() => navigate("/kitchen-registration/basic-details")}
        fullWidth
        variant="contained"
        color="success"
      >
        Create New Registration <ArrowForward />
      </Button>
      <Card style={{ marginTop: "20px" }}>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom>
            Keep the below details handy for smooth registration
          </Typography>
          <ListItem>PAN Number</ListItem>
          <ListItem>GSTIN Number</ListItem>
          <ListItem>Bank Details (Account number & IFSC)</ListItem>
          <ListItem>FSSAI Registration number</ListItem>
        </CardContent>
      </Card>
    </div>
  );
};

export default NewRegistration;
