import { useCallback, useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import {
  AddressDisplay,
  BankDetailsDisplay,
  BasicDetailsDisplay,
  ConfigurationDisplay,
  LegalDetailsDisplay,
  PricingDisplay,
} from "@/components/KitchenDetailsDisplay";
import Timeline from "@/components/Timeline";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import SubmitKitchenRegistrationButton from "./components/SubmitKitchenRegistrationButton";

const SummaryPage = () => {
  const navigate = useNavigate();
  const [kitchenRegistration, setKitchenRegistration] = useState({});

  const isEditable = ["created", "declined"].includes(
    kitchenRegistration.status,
  );

  const getKitchenRegistration = useCallback(async () => {
    try {
      const res = await api.kitchenRegistrations.getMyKitchenRegistration();
      setKitchenRegistration(res.data.kitchenRegistration);
    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    getKitchenRegistration();
  }, [getKitchenRegistration]);

  return (
    <>
      <Header />
      <Container>
        <Title>{kitchenRegistration.name}</Title>
        {isEditable && (
          <SubmitKitchenRegistrationButton
            fetchKitchenRegistration={getKitchenRegistration}
          />
        )}
        {kitchenRegistration.timeline && (
          <Timeline timeline={kitchenRegistration.timeline} />
        )}
        {kitchenRegistration.approvedKitchen && (
          <Button
            onClick={() => navigate("/")}
            fullWidth
            variant="contained"
            style={{ margin: "10px 0px" }}
            color="success"
            endIcon={<ArrowForward />}
          >
            Go to Kitchen Page
          </Button>
        )}
        {kitchenRegistration.ownerDetails && (
          <BasicDetailsDisplay
            isEditable={isEditable}
            kitchenName={kitchenRegistration.name}
            kitchenTagline={kitchenRegistration.tagline}
            ownerName={kitchenRegistration.ownerDetails.name}
            ownerPhoneNumber={kitchenRegistration.ownerDetails.mobile}
          />
        )}
        {kitchenRegistration.address && (
          <AddressDisplay
            isEditable={isEditable}
            address={kitchenRegistration.address}
          />
        )}
        {kitchenRegistration.configuration && (
          <ConfigurationDisplay
            isEditable={isEditable}
            configuration={kitchenRegistration.configuration}
          />
        )}
        {kitchenRegistration.configuration && kitchenRegistration.pricing && (
          <PricingDisplay
            isEditable={isEditable}
            configuration={kitchenRegistration.configuration}
            pricing={kitchenRegistration.pricing}
          />
        )}
        {kitchenRegistration.bankDetails && (
          <BankDetailsDisplay
            isEditable={isEditable}
            bankDetails={kitchenRegistration.bankDetails}
          />
        )}
        {kitchenRegistration.legalDetails && (
          <LegalDetailsDisplay
            isEditable={isEditable}
            legalDetails={kitchenRegistration.legalDetails}
          />
        )}
      </Container>
    </>
  );
};

export default SummaryPage;
