import { useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Alert, Button, Card, CardContent, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { useKitchen } from "@/contexts/KitchenContext";
import handleError from "@/utils/handleError";

const KitchenActivationPage = () => {
  const navigate = useNavigate();
  const { kitchen, fetchMyKitchenDetails } = useKitchen();
  const [pendingMenuScheduleCategories, setPendingMenuScheduleCategories] =
    useState([]);
  const isPendingMenuScheduleCategoriesAvailable =
    pendingMenuScheduleCategories.length > 0;

  useEffect(() => {
    const fetchPendingMenuScheduleCategories = async () => {
      try {
        const {
          data: { pendingMenuScheduleCategories },
        } = await api.menuSchedules.getPendingMenuScheduleCategories();
        setPendingMenuScheduleCategories(pendingMenuScheduleCategories);
      } catch (error) {
        handleError(error);
      }
    };
    fetchPendingMenuScheduleCategories();
  }, []);

  const handleKitchenActivation = async () => {
    try {
      await api.kitchens.activateMyKitchen();
      fetchMyKitchenDetails();
      toast.success("Kitchen activated successfully");
      navigate("/dispatch-tasks", { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  if (kitchen.status === "active") {
    return navigate("/", { replace: true });
  }

  return (
    <>
      <Header />
      <Container>
        <Title>Kitchen activation</Title>
        {isPendingMenuScheduleCategoriesAvailable ? (
          <Card>
            <CardContent>
              <Alert severity="info">
                Please complete the pending menu schedules to activate the
                kitchen
              </Alert>
              <Button
                fullWidth
                size="small"
                variant="contained"
                endIcon={<ArrowForward />}
                color="success"
                onClick={() => navigate("/menu-schedules/pending/categories")}
                style={{ marginTop: "10px" }}
              >
                Complete menu schedules
              </Button>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Alert severity="success">
                All menu schedule tasks are completed. You can activate the
                kitchen now
              </Alert>
              <Button
                size="small"
                fullWidth
                color="success"
                variant="contained"
                onClick={handleKitchenActivation}
                style={{ marginTop: "10px" }}
              >
                Activate Kitchen
              </Button>
            </CardContent>
          </Card>
        )}
      </Container>
    </>
  );
};

export default KitchenActivationPage;
