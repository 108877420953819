import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Address from "@/components/Address";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";
import { filterObj } from "@/utils/objectUtils";

const AddressPage = () => {
  const navigate = useNavigate();
  const [initialAddress, setInitialAddress] = useState(null);

  const addressSubmitHandler = async (address) => {
    try {
      await api.kitchenRegistrations.updateAddress(address);
      navigate(`/kitchen-registration/configuration`);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const res = await api.kitchenRegistrations.getMyKitchenRegistration();
        const address = res.data.kitchenRegistration.address;

        if (address) {
          setInitialAddress(
            filterObj(
              address,
              "building",
              "area",
              "landmark",
              "district",
              "state",
              "country",
              "pincode",
              "latitude",
              "longitude",
            ),
          );
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchAddress();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Address</Title>
        <Address
          initialAddress={initialAddress}
          submitHandler={addressSubmitHandler}
        />
      </Container>
    </>
  );
};

export default AddressPage;
