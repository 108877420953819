import { useCallback, useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import BottomButton from "@/components/BottomButton";
import FormComponents from "@/components/FormComponents";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import handleError from "@/utils/handleError";
import { filterObj } from "@/utils/objectUtils";

import DeliveryPincodes from "./components/DeliveryPincodes";

const schema = Yup.object().shape({
  cuisines: Yup.array().min(1, "Select at least one cuisine"),
  mealTypes: Yup.array().min(1, "Select at least one meal type"),
  mealTimes: Yup.array().min(1, "Select at least one meal time"),
  periods: Yup.array().min(1, "Select at least one period"),
  servingCapacityPerMealTime: Yup.number()
    .required("Capacity is required")
    .positive("Capacity must be a positive number"),
  deliveryDays: Yup.array().min(1, "Select at least one delivery day"),
});

const ConfigurationPage = () => {
  const navigate = useNavigate();
  const data = useData();
  const [deliveryPincodes, setDeliveryPincodes] = useState([]);

  const formBuilder = [
    {
      type: "multiSelect",
      label: "Cuisine",
      state: "cuisines",
      options: data.cuisinesData,
      value: "",
    },
    {
      type: "multiSelect",
      label: "Meal Type",
      state: "mealTypes",
      options: data.mealTypesData,
      value: "",
    },
    {
      type: "multiSelect",
      label: "Meal Time",
      state: "mealTimes",
      options: data.mealTimesData,
      value: "",
    },
    {
      type: "multiSelect",
      label: "period",
      state: "periods",
      options: data.periodsData,
      value: "",
    },
    {
      type: "input",
      inputType: "number",
      label: "Serving capacity per meal time",
      state: "servingCapacityPerMealTime",
      value: "",
    },
    {
      type: "multiSelect",
      label: "Delivery days",
      state: "deliveryDays",
      options: data.daysData,
      value: "",
    },
  ];

  const formik = useFormik({
    initialValues: {
      cuisines: [],
      mealTypes: [],
      mealTimes: [],
      periods: [],
      servingCapacityPerMealTime: "",
      deliveryDays: [],
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        if (deliveryPincodes.length === 0) {
          return toast.error("Please add atleast one delivery pincode");
        }
        await api.kitchenRegistrations.updateConfiguration({
          ...values,
          deliveryPincodes,
        });
        navigate(`/kitchen-registration/pricing`);
      } catch (error) {
        handleError(error);
      }
    },
  });

  const pincodeListHandler = useCallback(
    (pincodes) => setDeliveryPincodes(pincodes),
    [],
  );

  useEffect(() => {
    const fetchConfiguration = async () => {
      try {
        const res = await api.kitchenRegistrations.getMyKitchenRegistration();
        const { configuration } = res.data.kitchenRegistration;

        if (configuration) {
          formik.setValues(
            filterObj(
              configuration,
              "cuisines",
              "mealTypes",
              "mealTimes",
              "periods",
              "servingCapacityPerMealTime",
              "deliveryDays",
            ),
          );
          setDeliveryPincodes(configuration.deliveryPincodes);
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchConfiguration();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Configuration</Title>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents data={formBuilder} formik={formik} />
          <DeliveryPincodes
            initialPincodeList={deliveryPincodes}
            pincodeListHandler={pincodeListHandler}
          />
          <BottomButton>Pricing</BottomButton>
        </form>
      </Container>
    </>
  );
};

export default ConfigurationPage;
